<template>
    <b-row>
        <b-col>
            <b-row>
                <b-modal title="Assign Roles" ref="assignRolesForm" hide-footer>
                    <b-col cols="11" class="mx-auto">
                        <b-row>
                            <b-col>

                                <b-col>
                                    <b-row>
                                        <b-col cols="9">
                                            <b-input-group class="border border-grey rounded-sm">
                                                <b-form-input
                                                        class="form-text text-sm noFocus border-0 text-gray"
                                                        v-model="search"
                                                        v-on:keyup="onSearch"
                                                        type="text"
                                                        placeholder="Search">
                                                </b-form-input>
                                                <b-input-group-prepend
                                                        class="bg-white">
                                                    <i class="fas fa-search mx-3 text-gray hover:text-blue"
                                                       style="margin-top: 12px;" @click="onSearch"></i>
                                                </b-input-group-prepend>
                                            </b-input-group>
                                        </b-col>
                                        <b-col cols="3">
                                            <b-form-select v-model="limit" class="w-full form-select paginate-details"
                                                           v-on:change="fetch">
                                                <b-select-option value="15">15</b-select-option>
                                                <b-select-option value="20">20</b-select-option>
                                                <b-select-option value="50">50</b-select-option>
                                                <b-select-option value="100">100</b-select-option>
                                            </b-form-select>
                                        </b-col>
                                    </b-row>
                                    <b-row class="mt-2">
                                        <b-col cols="12">
                                            <b-row>
                                                <b-col>
                                                    <b-progress value="100" v-if="loading" variant="#007ac5"
                                                                height="0.2rem" animated></b-progress>
                                                </b-col>
                                            </b-row>
                                            <b-row class="flex py-2 px-3 border-b border-header_grey hover:bg-header_grey"
                                                   v-for="(registrar, idx) in getRegistrars"
                                                   :key="idx">
                                                <b-col :class="[(selectedId===registrar.id) ? 'border-t border-b botext-blue text-blue font-bold' : '']"
                                                       cols="9">
                                                    <div class="flex">
                                                        <b-col class="ml-3">
                                                            <b-row>
                                                                <b-col>
                                                                    <p class="text-sm text-uppercase" v-if="registrar">
                                                                        <span v-if="registrar.givenName">{{ registrar.givenName }}</span>
                                                                        <span class="mx-1"
                                                                              v-if="registrar.surName">{{ registrar.surName }}</span>
                                                                        <span class="mx-1" v-if="registrar.middleName">{{ registrar.middleName }}</span>
                                                                    </p>
                                                                </b-col>
                                                            </b-row>
                                                            <b-row>
                                                                <b-col>
                                                                    <p class="text-xs text-lowercase text-gray italic"
                                                                       v-if="registrar">{{ registrar.email }}</p>
                                                                </b-col>
                                                            </b-row>
                                                        </b-col>
                                                    </div>
                                                </b-col>
                                                <b-col :class="[(selectedId===registrar.id) ? 'border-t border-b botext-blue text-blue font-bold' : '']"
                                                       cols="3">
                                                    <button @click="selectUser(registrar)" class="button">
                                                            <span class="text-xs" v-if="selectedId===registrar.id">
                                                               Selected  <i
                                                                    class="text-blue fa fa-check-circle mx-1"></i>
                                                            </span>
                                                        <span v-else>
                                                                Select <i class="text-gray fa fa-check-circle mx-1"></i>
                                                            </span>
                                                    </button>
                                                </b-col>
                                            </b-row>
                                            <b-row class="flex py-1 px-2 bg-header_grey"
                                                   v-if="getRegistrars.length === 0">
                                                <b-col cols="12" class="text-center">
                                                    <p class="text-xs">No Account Found</p>
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                    </b-row>
                                </b-col>


                                <b-form-select v-model="assignForm.roleId" class="form-select my-2"
                                                   :options="rolesDataList"></b-form-select>
                                    <button
                                            class="bg-blue text-white px-2 py-1 rounded-sm hover:bg-blue-dark"
                                            type="button" @click="assignNewRole">
                                        Assign Role
                                        <b-spinner v-if="spinner" small></b-spinner>
                                    </button>

                            </b-col>
                        </b-row>
                    </b-col>
                </b-modal>
            </b-row>
            <b-row class="py-3">
                <b-col cols="2">
                    <b-form-input
                            class="text-gray text-sm noFocus ml-1"
                            style="margin-top: 1px; padding:6px 5px;"
                            v-model="search"
                            type="text"
                            placeholder="Search by Name"
                            required
                            v-on:keyup.enter="fetch">
                    </b-form-input>
                </b-col>
                <b-col cols="2">
                    <b-form-select
                            class="form-select text-sm noFocus text-gray"
                            v-model="type"
                            @change="fetch"
                            v-on:keyup.enter="fetch">
                        <b-select-option :value="null">--Filter by Gender--</b-select-option>
                        <b-select-option value="MALE">Male</b-select-option>
                        <b-select-option value="FEMALE">Female</b-select-option>

                    </b-form-select>
                </b-col>
                <b-col cols="2">
                    <b-form-select
                            class="form-select text-sm noFocus text-gray"
                            v-model="status"
                            @change="fetch"
                            v-on:keyup.enter="fetch">
                        <b-select-option :value="null">--Filter by Status--</b-select-option>
                        <b-select-option value="ALL">All</b-select-option>
                        <b-select-option value="ACTIVE">Active</b-select-option>
                        <b-select-option value="INACTIVE">Inactive</b-select-option>
                    </b-form-select>
                </b-col>
                <b-col cols="4"></b-col>
                <b-col cols="2">
                    <button class="w-full bg-blue text-white px-2 py-1 rounded-sm" @click="openAssignRolesForm">
                        <i class="fa fa-pencil mx-1"></i> Assign Roles
                    </button>
                </b-col>
            </b-row>
            <div class="mx-auto">
                <b-progress value="100" v-if="loading" variant="#007ac5" height="0.2rem" animated></b-progress>
            </div>
            <b-row>
                <b-col cols="12">
                    <table class="min-w-full leading-normal">
                        <thead>
                        <tr class="border-b border-t border-grey">
                            <th class="t-header" width="150px">Name</th>
                            <th class="t-header">ID Number</th>
                            <th class="t-header">Roles</th>
                            <th class="t-header">Status</th>
                            <th class="t-header">Action</th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(account, idx) in accounts" :key="idx">
                            <td class="t-body font-bold text-uppercase" width="150px">
                                <span>{{ account.givenName }} {{ account.surName }}</span>
                                <span class="mx-2" v-if="account.middleName !== 'null'">{{ account.middleName }}</span>
                            </td>
                            <td class="t-body">
                                <span style="text-transform: uppercase">{{ account.idNumber.split(',')[0] }}</span><br/>
                                <small class="text-green">({{ cleanUnderscoreString(account.idType) }})</small>
                            </td>
                            <td class="t-body">
                                <p v-for="(role, idx) in account.userRoles" :key="idx" class="mr-1">
                                    {{ cleanUnderscoreString(role.name.replace('ROLE', '').replace('USER', '')) }}
                                </p>

                            </td>
                            <td class="t-body font-bold">
                                <span class="text-green" v-if="account.enabled">Active</span>
                                <span class="text-red" v-else>Inactive</span>
                            </td>
                            <td class="t-body text-center">
                                <b-row>
                                    <b-col>
                                        <!--                                        <i class="fa fa-edit mr-1 text-blue"/>-->
                                    </b-col>
                                </b-row>
                            </td>
                        </tr>
                        <tr>
                            <td v-if="accounts.length === 0" colspan="12" class="bg-inertgrey text-center py-1">
                                <span class="text-sm">No results found</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </b-col>
            </b-row>
            <b-row class="mx-1" v-if="pagination.totalPages > 1">
                <b-col cols="1">
                    <button @click="previous" :class="(currentPage > 1) ? 'paginate-btn' :'inert-btn'">Previous</button>
                </b-col>
                <b-col cols="1">
                    <b-row>
                        <b-col cols="12">
                            <p class="paginate-details text-center">
                                <b-badge class="bg-blue text-white">
                                    {{ (currentPage + 1) }}/{{ pagination.totalPages }}
                                </b-badge>
                            </p>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="1">
                    <button @click="next" :class="(currentPage < pagination.totalPages) ? 'paginate-btn' :'inert-btn'">
                        Next
                    </button>
                </b-col>
                <b-col class="9"></b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {api} from "@/app/services/api.service";

export default {
    name: "PermissionAccounts",
    data() {
        return {
            loading: false,
            spinner: false,
            currentPage: 0,
            limit: 200,
            accounts: [],
            assignForm: {
                userId: null,
                roleId: null,
            },
            pagination: {
                totalElements: 0,
                totalPages: 0
            },
            rows: {
                odd: "border-t border-grey hover:bg-header_grey",
                even: "border-t border-grey bg-header_grey"
            },
            search: "",
            status: null,
            type: null,
            usersList: [{text: "Select User", id: null}],
            rolesDataList: [{text: "Select Role", id: null, disabled: true}],
            getRegistrars: [],
            loadingAssignment: false,
            selectedId: null,
        }
    },
    mounted() {
        this.fetch()
        this.fetchRoles()
    },
    methods: {
        ...mapActions(["fetchStaffAccounts", "fetchRoles", "fetchPermissions", "assignRole", "searchRegistrars"]),
        fetch() {
            this.loading = true;
            let filters = {"accountType": "BACKOFFICE"}

            if (this.status) {
                filters.accountStatus = this.status
            }
            filters.perPage = this.limit

            this.fetchStaffAccounts(this.objectToQueryParams(filters));
        },
        onSearch() {
            this.loading = true
            this.searchRegistrars({term: this.search})
        },
        async searchRegistrars(data) {
            this.loading = true
            this.errorMessage = null
            this.getRegistrars = []
            await api()
                .get("auth/api/users/staff?name=" + data.term.toUpperCase())
                .then(response => {
                    this.getRegistrars = response.data.data.content
                    this.loading = false
                })
                .catch(error => {
                    this.loading = false
                    this.errorMessage = error.response.data.message
                });
        },
        selectUser(user) {
            this.assignForm.userId = user.id
            this.selectedId = user.id
        },
        next() {
            if (this.currentPage < this.pagination.totalPages) {
                this.currentPage++
                this.fetch()
            }
        },
        filterUserList(dataList) {
            let dataObj = [{text: "Select User", id: null}];
            let dataJson = {};
            for (let i = 0; i < dataList.length; i++) {
                let userData = dataList[i]
                dataJson = {
                    text: userData.surName + " " + userData.givenName,
                    value: dataList[i].id
                };
                dataObj.push(dataJson);
            }
            return dataObj;
        },
        assignNewRole() {
            this.spinner = true
            this.errorMessage = null
            let data = {
                userId: this.assignForm.userId,
                roleIds: [this.assignForm.roleId]
            }

            this.assignRole(data)
        },

        filterRolesListData(dataList) {
            let dataObj = [{text: "Select Role", id: null, disabled: true}];
            let dataJson = {};
            for (let i = 0; i < dataList.length; i++) {
                dataJson = {
                    text: dataList[i].name,
                    value: dataList[i].roleId
                };
                dataObj.push(dataJson);
            }
            return dataObj;
        },
        openAssignRolesForm() {
            this.$refs.assignRolesForm.show();
        },
        previous() {
            if (this.currentPage > 1) {
                this.currentPage--
                this.fetch()
            } else {
                this.warningToast("Observation", "No more data to show")
            }
        },
    },
    computed: {
        ...mapGetters(["getOfficerAccounts", "getRolesList", "getNewRole"])
    },
    watch: {
        getOfficerAccounts(data) {
            // alert(JSON.stringify(data.data))
            this.loading = false;
            this.accounts = data.data.content;
            this.usersList = this.filterUserList(data.data.content)
        },
        getNewRole() {
            this.successToast("Success", "Role Assigned Successfully")
            this.spinner = false
            this.form.userId = null
            this.$refs.assignRolesForm.hide()
            this.$emit("finished", true)
        },
        getRolesList(rolesList) {
            if (rolesList) {
                this.rolesDataList = this.filterRolesListData(rolesList)
            }
        },
    }
};
</script>

<style scoped>

</style>