import {api} from "@/app/services/api.service";

const state = {
    company_registry: null,
    business_name_registry: null,
    building_society_registry: null,
    black_list_registry: null,
    mda_list_registry: null,
    entity_file: null,
    edms_file: null,
    error: null,
    edms_error: null
};

const getters = {
    getEntityFile: (state) => state.entity_file,
    getEntityFileError: (state) => state.edms_error,
    getEDMSFile: (state) => state.edms_file,
    getCompanyRegistry: (state) => state.company_registry,
    getBuildingSocietyRegistry: (state) => state.building_society_registry,
    getBlacklistRegistry: (state) => state.black_list_registry,
    getMDAListRegistry: (state) => state.mda_list_registry,
    getBusinessNameRegistry: (state) => state.business_name_registry,
    getRegistryError: (state) => state.error,
};

const actions = {
    async fetchCompanyRegistry({commit}, data) {
        await api()
            .get("/central-registry/api/companies?page="+data.page+"&limit="+data.limit+"&offset="+data.offset)
            .then(response => {
                commit("setCompanyRegistry", response.data);
            })
            .catch(error => {
                commit("setRegistryError", error.response);
            });
    },
    async searchCompanyRegistry({commit}, data) {
        await api()
            .post("/central-registry/api/companies/search", data)
            .then(response => {
                commit("setCompanySearchResults", response.data);
            })
            .catch(error => {
                commit("setRegistryError", error.response);
            });
    },
    async fetchCompanyByBRN({commit}, brn) {
        await api()
            .get("/central-registry/api/companies/"+brn+"/info")
            .then(response => {
                commit("setEntityFile", response.data);
            })
            .catch(error => {
                commit("setRegistryError", error.response);
            });
    },
    async fetchBuildingSocietyRegistry({commit}, data) {
        await api()
            .get("/central-registry/api/building-societies?page="+data.page+"&limit="+data.limit+"&offset="+data.offset)
            .then(response => {
                commit("setBuildingSocietyRegistry", response.data);
            })
            .catch(error => {
                commit("setRegistryError", error.response);
            });
    },

    async fetchBuildingSocietyByBRN({commit}, brn) {
        await api()
            .get("/central-registry/api/building-societies/"+brn+"/by-brn")
            .then(response => {
                commit("setEntityFile", response.data);
            })
            .catch(error => {
                commit("setRegistryError", error.response);
            });
    },
    async fetchBusinessNameRegistry({commit}, data) {
        await api()
            .get("/central-registry/api/business-names?page="+data.page+"&limit="+data.limit+"&offset="+data.offset)
            .then(response => {
                commit("setBusinessNameRegistry", response.data);
            })
            .catch(error => {
                commit("setRegistryError", error.response);
            });
    },
    async searchBusinessNameRegistry({commit}, data) {
        await api()
            .post("/central-registry/api/business-names/search", data)
            .then(response => {
                commit("setBusinessNameSearchResults", response.data);
            })
            .catch(error => {
                commit("setRegistryError", error.response);
            });
    },
    async fetchBusinessNameByBRN({commit}, brn) {
        await api()
            .get("/central-registry/api/business-names/"+brn+"/by-brn")
            .then(response => {
                commit("setEntityFile", response.data);
            })
            .catch(error => {
                commit("setRegistryError", error.response);
            });
    },
    async fetchCompanyEDMSFile({commit}, data) {
        await api()
            .post("/integration/api/edms/company/files/all", data)
            .then(response => {
                commit("setEDMSFile", response.data);
            })
            .catch(error => {
                commit("setEDMSError", error.response);
            });
    },
    async fetchBusinessNameEDMSFile({commit}, data) {
        await api()
          .post("/integration/api/edms/business-name/files/all", data)
            .then(response => {
                commit("setEDMSFile", response.data);
            })
            .catch(error => {
                commit("setRegistryError", error.response);
            });
    },
    async fetchSocietyEDMSFile({commit}, data) {
        await api()
            .post("/integration/api/edms/company/files/all", data)
            .then(response => {
                commit("setEDMSFile", response.data);
            })
            .catch(error => {
                commit("setRegistryError", error.response);
            });
    },

    async uploadBatchBrnsForStrikingOff({commit}, data) {
        await api()
            .post("/data-update/blacklisted-brns", data)
            .then(response => {
                commit("setEntityFile", response.data);
            })
            .catch(error => {
                commit("setRegistryError", error.response);
            });
    },
    async fetchBlackListRegistry({commit}, data) {
        await api()
            .get("/data-update/blacklisted-brns?page=" + data.page + "&pageSize=" + data.limit + "&offset=" + data.offset + "&status=" + data.status + "&search=" + data.search)
            .then(response => {
                commit("setBlackListRegistry", response.data);
            })
            .catch(error => {
                commit("setRegistryError", error.response);
            });
    }, async fetchMDAListRegistry({commit}, data) {
        await api()
            .get("/configuration/mda-registry?page=" + data.page + "&limit=" + data.limit + "&search=" + data.search)
            .then(response => {
                commit("setMDAListRegistry", response.data);
            })
            .catch(error => {
                commit("setRegistryError", error.response);
            });
    },
};

const mutations = {
    setEDMSFile: (state, response) => {
        state.edms_file = response;
    },
    setEntityFile: (state, response) => {
        state.error = null;
        state.entity_file = response;
    },
    setCompanyRegistry: (state, response) => {
        state.error = null;
        state.company_registry = response.content;
    },
    setCompanySearchResults: (state, response) => {
        state.error = null;
        state.company_registry = response;
    },
    setBusinessNameSearchResults: (state, response) => {
        state.error = null;
        state.business_name_registry = response;
    },
    setBuildingSocietySearchResults: (state, response) => {
        state.error = null;
        state.building_society_registry = response;
    },
    setBusinessNameRegistry: (state, response) => {
        state.error = null;
        state.business_name_registry = response.content;
    },
    setBuildingSocietyRegistry: (state, response) => {
        state.error = null;
        state.building_society_registry = response.content;
    }, setBlackListRegistry: (state, response) => {
        state.error = null;
        state.black_list_registry = response;
    }, setMDAListRegistry: (state, response) => {
        state.error = null;
        state.mda_list_registry = response;
    },
    setRegistryError: (state, response) => {
        state.error = response.data;
    },
    setEDMSError: (state, response) => {
        state.edms_error = response.data;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
