<template>
    <div class="w-full">

        <b-row>
            <b-col>
                <b-row>
                    <b-modal size="lg" title="View Blacklist reversal history" ref="viewReversalHistory" hide-footer>
                        <b-col cols="11" class="mx-auto">
                            <b-row>
                                <b-col>
                                    <table class="table  table-striped">

                                        <thead>
                                        </thead>
                                        <tbody class="table-striped">
                                        <tr>
                                            <td>Name</td>
                                            <td>{{ reversalHistory.companyName }}</td>
                                        </tr>
                                        <tr>
                                            <td>Date added</td>
                                            <td>{{ reversalHistory.createdAt }}</td>
                                        </tr>
                                        <tr>
                                            <td>Date updated</td>
                                            <td>{{ reversalHistory.updatedAt }}</td>
                                        </tr>
                                        <tr>
                                            <td>Added by</td>
                                            <td>{{ reversalHistory.addedBy }}</td>
                                        </tr>
                                        </tbody>
                                    </table>

                                    <table class="table border">

                                        <thead>
                                        <tr></tr>
                                        <td>Historical changes</td>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(rev,idx) in reversalHistory.history" :key="idx">
                                            <td>
                                                {{ rev.createdAt }}
                                            </td>
                                            <td>

                                                <div v-if="rev.fromStatus ==='OFF'">
                                                    <i class="fa fa-arrow-left text-green"></i>
                                                    Removed from black list
                                                </div>
                                                <div v-else>
                                                    <i class="fa fa-arrow-right text-red"></i>
                                                    Added to black list
                                                </div>


                                                <!--                                                {{reversalHistory.name}}-->
                                            </td>
                                            <td>
                                                {{ rev.addedBy }}
                                            </td>
                                            <td>
                                                <!--                                                {{reversalHistory.date}}-->
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-modal>
                </b-row>
            </b-col>
        </b-row>


        <b-row>
            <b-col cols="12" class="mx-3 mt-3">

                <span class="title">Black List  Registry</span>

            </b-col>
            <b-col>

                <b-row>
                    <b-modal size="lg" ref="strikeCompanyModal" title="Add companies  to de-register"
                             hide-footer>
                        <b-row>
                            <b-col class="mx-auto mb-2">
                                <div class="flex h-full" style="cursor: pointer;">
                                    <div :class="[(option === 'form') ? 'menu-item-active' : 'menu-item']"
                                         @click="select('form')">
                                        <div class="flex mt-6">
                                            <div style="font-size: 14px" class="mt-1">Enter BRN</div>
                                        </div>
                                    </div>
                                    <div :class="[(option === 'upload') ? 'menu-item-active' : 'menu-item']"
                                         @click="select('upload')">
                                        <div class="flex mt-6">
                                            <div style="font-size: 14px" class="mt-1">Upload File</div>
                                        </div>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="8" class="mx-auto">
                                <form v-if="option === 'form'">
                                    <b-form-input
                                            v-model="form.brn"
                                            placeholder="Add BRN here"
                                            rows="6">
                                    </b-form-input>


                                    <button class="bg-blue text-white px-3 py-1 rounded-sm mt-2 float-end" @click="save"
                                            type="button">
                                        Submit
                                        <b-spinner v-if="spinner" small></b-spinner>
                                    </button>
                                </form>
                                <form @submit.prevent="saveUpload" v-if="option === 'upload'">
                                    <b-input-group>
                                        <b-form-file
                                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                label-size="sm"
                                                type="file"
                                                placeholder="Please provide the excel file..."
                                                drop-placeholder="Drop file here..."
                                                v-model="form.file"
                                                class="form-control noFocus removeOnClickBorder rounded-0 mb-2"
                                                plain></b-form-file>
                                    </b-input-group>
                                    <button class="bg-blue text-white px-3 py-1 rounded-sm mt-2 float-end"
                                            type="submit">
                                        Upload
                                        <b-spinner v-if="spinner" small></b-spinner>
                                    </button>
                                </form>
                            </b-col>
                        </b-row>
                    </b-modal>
                </b-row>
                <b-row>
                    <b-col>
                        <b-row>
                            <b-col cols="10"></b-col>
                            <b-col cols="2">
                                <button class="w-full bg-blue text-white px-2 py-1 rounded-sm text-sm"
                                        @click="openForm"><i
                                        class="fa fa-plus"></i>
                                    Strike/Blacklist Company
                                </button>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" class="my-2">
                <b-row>
                    <b-col cols="2">
                        <b-form-input
                                class="text-gray text-sm noFocus ml-1"
                                style="margin-top: 1px; padding:6px 5px;"
                                v-model="search"
                                type="text"
                                placeholder="Search by Name or BRN"
                                required
                                v-on:keyup.enter="fetch">
                        </b-form-input>
                    </b-col>

                    <b-col cols="2">
                        <b-form-select
                                class="form-select text-sm noFocus text-gray"
                                v-model="status"
                                v-on:keyup.enter="fetch">
                            <b-select-option :value="null">--Filter by Status--</b-select-option>
                            <b-select-option value="Registered">Registered</b-select-option>
                            <b-select-option value="Insolvent">Insolvent</b-select-option>
                            <b-select-option value="Ceased">Ceased</b-select-option>

                        </b-form-select>
                    </b-col>
                    <b-col cols="3"></b-col>
                    <b-col cols="1">
                        <b-form-select v-model="limit" class="w-full text-xs form-select border-0 paginate-details"
                                       v-on:change="fetch">
                            <b-select-option value="15">15</b-select-option>
                            <b-select-option value="20">20</b-select-option>
                            <b-select-option value="50">50</b-select-option>
                            <b-select-option value="100">100</b-select-option>
                        </b-form-select>
                    </b-col>
                    <b-col cols="2">
                        <b-row>
                            <b-col class="mx-auto">
                                <p class="paginate-details text-center text-xs font-bold">
                                    <span>{{ ((currentPage - 1) * limit) + 1 }}</span> -
                                    <span v-if="limit > entities.length ">{{ pagination.totalElements }}</span>
                                    <span v-else>{{ currentPage * limit }}</span>
                                    of {{ pagination.totalElements }}
                                </p>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>

        <div class="mx-auto">
            <b-progress value="100" v-if="loading" variant="#007ac5" height="0.2rem" animated></b-progress>
        </div>

        <b-row>
            <b-col cols="12">
                <table class="min-w-full leading-normal">
                    <thead>
                    <tr class="border-b border-t border-grey">
                        <th class="t-header">#</th>
                        <th class="t-header">BRN</th>
                        <th class="t-header">Name</th>
                        <th class="t-header text-center">Date</th>
                        <th class="t-header text-center">Added By</th>
                        <th class="t-header text-center">Action</th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(entity, idx) in entities" :key="idx">
                        <td class="t-body">{{ (currentPage - 1) * limit + idx + 1 }}</td>
                        <td class="t-body text-blue font-bold">
                            <span class="hover:text-blue-dark cursor-pointer underline"
                                  @click="viewFile(entity.brn)">{{ entity.brn }}</span>
                        </td>

                        <td class="t-body text-black font-bold">
                            <span class="hover:text-blue-dark cursor-pointer underline"
                                  @click="viewFile(entity.brn)">{{ entity.companyName }}</span>
                        </td>


                        <td class="t-body text-center">
                            {{ dateFormat(entity.createdAt) }}
                        </td>
                        <td class="t-body text-center">
                <span class="px-1 py-1 text-green text-xs">
                  {{ entity.addedBy }}
                </span>
                        </td>
                        <td class="text-center">
                            <button class="bg-blue text-white mx-2 rounded p-2 text-xs hover:bg-blue-dark"
                                    @click="getReversalHistory(entity.brn)">
                                <i class="fa fa-eye text-white mr-2"></i>History
                            </button>
                            <button class="bg-red text-white rounded-sm px-2 py-1 text-xs hover:bg-red-dark"
                                    @click="reverseBlackList(entity.brn)">Reverse
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td v-if="entities.length === 0" colspan="8" class="bg-inertgrey text-center py-1">
                            <span class="text-sm">No results found</span>
                        </td>
                    </tr>
                    <tr>
                        <td v-if="hasError" colspan="12" class="bg-red text-center py-1 text-white">
                            <p class="text-sm">Failed to load tasks, Try again later.</p>
                            <p class="text-sm">{{ errorMessage }}</p>
                            <p class="text-sm">
                                <button class="bg-white text-red px-2 rounded-sm" @click="fetch">Reload
                                    <b-spinner v-if="loading" small></b-spinner>
                                </button>
                            </p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </b-col>
        </b-row>
        <b-row class="mx-1" v-if="pagination.totalPages > 1">
            <b-col cols="1">
                <button @click="previous" :class="(currentPage > 1) ? 'paginate-btn' :'inert-btn'">Previous</button>
            </b-col>
            <b-col cols="1">
                <b-row>
                    <b-col cols="12">
                        <p class="paginate-details text-center">
                            <b-badge class="bg-blue text-white">
                                {{ (currentPage) }}/{{ pagination.totalPages }}
                            </b-badge>
                        </p>
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="1">
                <button @click="next" :class="(currentPage < pagination.totalPages) ? 'paginate-btn' :'inert-btn'">
                    Next
                </button>
            </b-col>
            <b-col cols="6"></b-col>
            <b-col cols="1">
                <b-form-select v-model="limit" class="w-full text-xs form-select border-0 paginate-details"
                               v-on:change="fetch">
                    <b-select-option value="15">15</b-select-option>
                    <b-select-option value="20">20</b-select-option>
                    <b-select-option value="50">50</b-select-option>
                    <b-select-option value="100">100</b-select-option>
                </b-form-select>
            </b-col>
            <b-col cols="2">
                <b-row>
                    <b-col class="mx-auto">
                        <p class="paginate-details text-center text-xs font-bold">
                            <span>{{ ((currentPage - 1) * limit) + 1 }}</span> -
                            <span v-if="limit > entities.length ">{{ pagination.totalElements }}</span>
                            <span v-else>{{ currentPage * limit }}</span>
                            of {{ pagination.totalElements }}
                        </p>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex"
import router from "@/router";
import moment from "moment";
import {api} from "@/app/services/api.service";

export default {
    name: "BlackListRegistry",
    data() {
        return {
            loading: false,
            currentPage: 1,
            limit: 15,
            entities: [],
            pagination: {
                totalElements: 0,
                totalPages: 0
            },
            assignForm: {
                taskId: null,
                toUserId: null
            },
            deAssignForm: {
                reservationId: null,
            },
            rows: {
                odd: "border-t border-grey hover:bg-header_grey",
                even: "border-t border-grey bg-header_grey"
            },
            search: "",
            status: null,
            type: null,
            hasError: false,
            errorMessage: null,
            reversalHistory: {
                brn: null,
                companyName: "",
                createdAt: "",
                updatedAt: "",
                addedBy: "",
                lastStatusUpdateDoneBy: "null",
                history: []
            },
            form: {
                file: null,
                brn: null
            },
            option: "form",
            spinner: false,
        }
    },
    mounted() {
        this.fetch()
    },
    methods: {
        ...mapActions(["fetchBlackListRegistry", "uploadBatchBrnsForStrikingOff"]),

        fetch() {
            let data = {
                search: this.search,
                page: this.currentPage,
                limit: this.limit,
                offset: this.limit,
                status: "ON"
            }
            this.loading = true
            this.fetchBlackListRegistry(data)
        },
        select(route) {
            this.option = route
        },

        saveUpload() {
            let formData = new FormData;
            formData.set("file", this.form.file);
            this.spinner = true
            this.uploadBatchBrnsForStrikingOff(formData);
        },
        next() {
            if (this.currentPage < this.pagination.totalPages) {
                this.currentPage++
                this.fetch()
            }
        },
        previous() {
            if (this.currentPage > 1) {
                this.currentPage--
                this.fetch()
            } else {
                this.warningToast("Observation", "No more data to show")
            }
        },
        viewFile(brn) {
            localStorage.setItem("entityType", "building-society")
            router.push("/home/registry/file-viewer/" + brn)
            router.go(0)
        },
        reverseBlackList(brn) {
            this.loading = true
            this.removeFromBlackListRegistry(brn)
        },

        async removeFromBlackListRegistry(brn) {
            await api()
                .post("/data-update/blacklisted-brns/" + brn, brn)
                .then(response => {

                    this.successToast("Success", "Removed from  the black list")
                    this.fetch()
                    console.log(response)
                })
                .catch(error => {
                    this.errorToast("Error", error.response.data.message)
                });
        },

        async getReversalHistory(brn) {
            await api()
                .get("/data-update/blacklisted-brns/" + brn)
                .then(response => {
                    this.reversalHistory = response.data.data
                    this.$refs.viewReversalHistory.show()
                })
                .catch(error => {
                    this.errorToast("Error", error.response.data.message)
                });
        },
        dateFormat(date) {
            return moment(date).format("DD-MM-YYYY")
        },
        cleanEntityType(name) {
            let n = name.replaceAll("-", " ")
            return n.charAt(0).toUpperCase() + n.slice(1)
        },
        openForm() {
            this.$refs.strikeCompanyModal.show()
        },
        async save() {
            this.spinner = true
            let data = {"registration_no": this.form.brn, "old_registration_no": "-"}

            await api().post("/integration/get-entity-full", data)
                .then(response => {
                    let payload = {
                        "brn": this.form.brn,
                        "companyName": response.data.entity_name
                    }
                    this.strikeCompanyOffRegistry(payload)

                }).catch(error => {
                    this.errorToast("Error ", error.response.data.message)
                });

        },
        async strikeCompanyOffRegistry(data) {

            await api()
                .post("/data-update/blacklisted-brns/" + data.brn + "/add", data)
                .then(response => {
                    this.successToast("Success", "Added to black list")
                    this.spinner = false
                    this.form.brn = null
                    console.log(response)
                })
                .catch(error => {
                    this.errorToast("Error ", error.response.data.message)
                    this.spinner = false
                });
        },
    },
    computed: {
        ...mapGetters(["getBlacklistRegistry", "getRegistryError"])
    },
    watch: {
        getBlacklistRegistry(data) {
            this.loading = false
            this.entities = data.content
            if (data.totalPages !== undefined) {
                this.pagination = {
                    totalPages: data.totalPages,
                    totalElements: data.totalElements
                }
            }
        },
        getRegistryError(data) {
            this.loading = false
            this.hasError = true
            this.errorMessage = data.message
        }
    }
}
</script>

<style scoped>
@import "../../../../../assets/styles/table.css";

.verified {
    @apply text-xs font-bold;
    color: green;
    font-style: italic;
}

.unverified {
    @apply text-xs font-bold;
    color: #b80404;
    font-style: italic;
}

.assign-btn {
    @apply px-3 py-1 bg-white border-2 font-bold border-green text-green text-xs w-full;
}

.assign-btn:hover {
    @apply bg-green text-white;
}

.de-assign-btn {
    @apply px-3 py-1 bg-white border-2 border-red text-red font-bold text-xs w-full;
}

.de-assign-btn:hover {
    @apply bg-red text-white;
}

.paginate-details {
    @apply px-3 py-2 bg-white my-2 text-sm text-gray;
}

.paginate-btn {
    @apply px-3 py-2 bg-white border border-white my-2 text-sm text-blue w-full;
}

.paginate-btn:hover {
    @apply border border-blue text-blue rounded-sm;
}

.inert-btn {
    @apply px-3 py-2 bg-white my-2 text-sm text-grey w-full;
}
</style>